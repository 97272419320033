import firebase from 'firebase/app'
import router from '@/router'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useReactNativeEvent } from './useReactNativeEvent'
import { ElNotification } from 'element-plus'
import { computed } from 'vue'
export interface UseAuth {
    currentUser: () => firebase.User | null;
    loginFacebook: () => Promise<firebase.auth.UserCredential> | any;
    loginGoogle: () => Promise<firebase.auth.UserCredential> | any;
	signInWithCustomToken: (customToken: string) => Promise<firebase.auth.UserCredential> | any;
	signOut: () => void;
	getRedirectResult: () => void;
	loginWithEmailAndPassword: (email: string, password: string) => Promise<firebase.auth.UserCredential> | any;
}

const useAuth = (): UseAuth => {
	const currentUser = () => firebase.auth().currentUser
	const userStore = useUserStore()
	const isReactNativeWebview = computed(() => window.ReactNativeWebView)
	const { emitSignout } = useReactNativeEvent()
	// const 

		
	const loginFacebook = () => {
		const provider = new firebase.auth.FacebookAuthProvider()
		provider.addScope('email')
		provider.addScope('public_profile')
		return firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				if (result) {
					/** @type {firebase.auth.OAuthCredential} */
					const credential = (result.credential) as firebase.auth.OAuthCredential
					const firebaseUserData = result.user
	
					// accessToken.value = credential?.accessToken
					userStore.updateUserData(firebaseUserData)
					userStore.updateProviderAccessToken(credential.accessToken as string)
				}
				return result
			})
			.catch((error) => {
				console.error(error)
				ElNotification({
					title: error?.code,
					message: error?.message || 'Something When Wrong',
					showClose: !isReactNativeWebview.value
				})
				return error
			})
	}

	const loginGoogle = () => {
		const provider = new firebase.auth.GoogleAuthProvider()
		return firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				if (result) {
					/** @type {firebase.auth.OAuthCredential} */
					const credential = (result.credential) as firebase.auth.OAuthCredential
					const firebaseUserData = result.user
	
					// accessToken.value = credential?.accessToken
					userStore.updateUserData(firebaseUserData)
					userStore.updateProviderAccessToken(credential.accessToken as string)
				}
				return result
			})
			.catch((error) => {
				console.error(error)
				ElNotification({
					type: 'error',
					title: error?.code,
					message: error?.message || 'Something When Wrong',
					showClose: !isReactNativeWebview.value
				})			
				return error
			})
	}

	const signInWithCustomToken = (customToken: string) => {
		//
		return firebase
			.auth()
			.signInWithCustomToken(customToken)
			.then((result) => {
				if (result) {
					const user = result.user
					userStore.updateUserData(user)
					/** @type {firebase.auth.OAuthCredential} */
					// const credential = (result.credential) as firebase.auth.OAuthCredential
					// const firebaseUserData = result.user
	
					// // accessToken.value = credential?.accessToken
					// userStore.updateUserData(firebaseUserData)
					// userStore.updateProviderAccessToken(credential.accessToken as string)
				}
				return result
			})
			.catch((error) => {
				console.error(error)
				return error
			})
	}

	const signOut = async () => {
		
		await firebase.auth().signOut()
		// emit react native
		emitSignout()
		// clear all localPersisted data in local storage
		userStore.$reset()
		localStorage.removeItem('live')
		await router.push('/login')
	}

	const getRedirectResult = () => {
		firebase.auth().getRedirectResult()
			.then((result) => {
				if (result) {
					/** @type {firebase.auth.OAuthCredential} */
					const credential = (result.credential) as firebase.auth.OAuthCredential
					const firebaseUserData = result.user
	
					// accessToken.value = credential?.accessToken
					userStore.updateUserData(firebaseUserData)
					userStore.updateProviderAccessToken(credential.accessToken as string)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const loginWithEmailAndPassword = (email: string, password: string) => {
		return firebase.auth().signInWithEmailAndPassword(email, password)
			.then((result) => {
				if (result) {
					/** @type {firebase.auth.OAuthCredential} */
					const credential = (result.credential) as firebase.auth.OAuthCredential
					const firebaseUserData = result.user

					// accessToken.value = credential?.accessToken
					userStore.updateUserData(firebaseUserData)
					userStore.updateProviderAccessToken(credential.accessToken as string)
				}
			})
	}

	return {
		currentUser,
		loginFacebook,
		loginGoogle,
		signOut,
		getRedirectResult,
		signInWithCustomToken,
		loginWithEmailAndPassword,
	}
}

export default useAuth